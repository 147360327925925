.menu-is-fixed {
  .settings__outer {
    display: none;
  }
}

.settings__outer {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index:20;
}

.dd__link {
  border: none;
  background: transparent;
  display: none;
  transition: none;

  &.open {
    background: $bg-grey;
    border: none;
  }
}

.sr-title {
  opacity: 0;
  position: absolute;
  top: 100%;
  background: #fff;
  width: 60px;
  text-align: center;
  left: 25%;

  z-index: -1000;
  box-shadow: 5px 4px 11px -4px rgb(0, 0, 0, 0.16);
  display: block;
  transition: opacity 0.3s ease-in-out;
  font-size: 10px;
  padding: 5px;

}


.settings__wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  top: -1px;
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #F6F7FA;
  border-top: none;
  transition: top 0.3s ease-in-out;
  padding-left:5px;

  button {
    background: none;
    border: none;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    height: 30px;

  }

  a,
  button {
    position: relative;
    width: 26px;
    height: 26px;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 2px;
    margin-left: 2px;

    &:hover,
    &:focus {
      .sr-title {
        z-index: 10;
        opacity: 1;
      }
    }

    &[aria-pressed="true"] {

      background: $main;
      color: #fff;
    }
  }

  .dd__link {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

}


.menu-is-fixed .settings__wrapper {
  top: 0;
}

.settings__option--l {


  a,
  button {
    width: 22px;
    height: 22px;
    margin-right: 0;
    margin-left: 0;
    color:#000;
  }
}

.settings__option {
  display: flex;
  align-items: center;
  border-right: 1px solid $line;
  padding-right: 5px;
}

.settings__t1 {
  font-size: $font12;
  display: inline-block;
  padding-left: 5px;

  @media(max-width: 768px) {
    display: none;

  }

}

.settings__t2 {
  font-size: $font12;
  font-weight: 700;
  display: inline-block;
  padding-left: 10px;
  padding-right: 5px;

  @media(max-width: 768px) {
    width: 0;
    opacity: 0;
    z-index: -1000;
    overflow: hidden;
    position: absolute;

  }
}

.settings__a--d {
  font-size: $font12;

}

.settings__a--w {
  font-size: $font15;
  font-weight: 700;
}

.settings__a--n {
  font-size: $font20;
  font-weight: 700;
}


.settings__wrapper {
  .settings__link--reset {
    &:before {
      content: url("../img/s-icon-reset.svg");
    }
  }

  .settings__link--nc {


    &:before {
      content: url("../img/s-icon-night.svg");
    }
  }

  .settings__link--big {
    &:before {
      content: url("../img/s-icon-dark.svg");
    }
  }

  .settings__link--swiper {
    width: 48px;
    height: 26px;
    border-radius: 13px;
    background: #fff;
    display: block;
    position: relative;
    margin-left: 5px;

    &:before {
      position: absolute;
      transition: left 0.3s ease-out;
      top: 3px;
      left: 3px;
    }
  }
}

.settings__wrapper button.settings__link--swiper[aria-pressed=true] {
  &:before {
    left: 25px;
  }
}

@media(max-width: 1700px) {
  .settings__outer {
    left: 60%;
  }
}


@media(max-width: 950px) {
  header {
    .header__wrapper {
      padding-top: 40px;
    }
  }
  .settings__outer {
    left: 50%;
  }
  .header.fixed-header-scroll .header__wrapper {
    padding-top:5px;
  }
}

@media(max-width: 1200px) {
  header {
    .header__wrapper {
      height: 155px;
    }
  }
}