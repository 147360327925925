@import "components/variables";
@import "components/settings";
@import "components/fonts";
@import "components/reset";
@import "components/grid.scss";
@import "components/typography.scss";
@import "components/form-goTop.scss";
@import "components/footer.scss";
@import "components/header.scss";
@import "components/accordion.scss";
@import "components/btns.scss";
@import "components/hero.scss";
@import "components/modal.scss";
@import "components/file.scss";



.idea__txt {
  p {
    margin-bottom: 20px;
  }
}

.speaker__list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-height: 520px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-out;
  padding-bottom: 30px;

  &.open {
    max-height: 3000px;
  }

  &:not(.open) {
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 160px;
      z-index: 10;
      background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 1) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }

  }
}

.speaker__wrapper {
  position: relative;
  padding-bottom: 100px;
}

.speaker__showAll {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 210px;

  :nth-child(2) {
    display: none;
  }

  &.active {
    span {
      display: none;
    }

    :nth-child(2) {
      display: block;
    }
  }
}

.speaker__btn {
  opacity: 0;
  color: #fff;
  transition: all 0.3s ease-out;
  background: transparent;
  border: none;
  text-transform: uppercase;
  padding: 0;
  padding: 10px 0;
  font-size:12px;
  display:block;

  &:after {
    content: url("../img/arrow-more.svg");
    position: relative;
    bottom: 2px;
  }


}

.speaker__item {
  max-width: calc(20% - 12px);
  flex: 0 0 20%;
  background: $bg-grey2;
  transition: all 0.3s ease-out;
  cursor:pointer;

  figure {
    padding-top: 86%;
    height: 0;
    width: 100%;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }
  }

  @media(hover: hover) {
    &:hover {
      background: $main2;
      color: #fff;

      figure img {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
      }

      .speaker__btn {
        opacity: 1;
      }

      .speaker__name {
        color: #fff;
      }
    }
  }
  @media(hover: none) {
    .speaker__btn {
      opacity: 1;
      color: $txt-dark;

      &:after {
        filter: brightness(0);
      }
    }
  }
}

.speaker__name {
  font-size: $font14;
  color: $txt-dark;
  margin-bottom: 10px;
  font-weight: 500;

  strong {
    font-size: $font16;
    display: block;
  }
}

.speaker__txt {
  font-size: 13px;
  margin-borrom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

  p:not(:first-child) {
    display: none;
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.speaker__bottom {
  padding: 10px 20px;
}


.schedule {
  background: $bg-grey;
  padding-bottom: 60px;
}

.schedule__title {
  display: flex;
  align-items: center;
  gap: 30px;
}

.title__data {
  color: $txt-dark;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 20px;
  border: 1px solid $main;

  >:first-child {
    font-size: 54px;

  }
}

.schedule__item {
  border-left: 4px solid rgba(128, 112, 77, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  font-size: $font13;
  padding-bottom: 20px;
  height: auto !important;
  transition: all 0.3s ease-out;
  cursor: pointer;

  p {
    margin-bottom: 7px;
  }

  h4 {
    color: $txt-dark;
    min-height: 50px;
    margin-bottom:15px;
    font-size: $font18;
    margin-bottom: 20px;

  }

  @media(hover: hover) {
    &:hover {
      background: #FBFAF9;
      border-color: #FBFAF9;
    }

  }
}

.schedule__time {
  color: $main3;
  font-size: $font27;
  margin-bottom: 20px;
  display: block;
}

.swiper__nav {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 48px;
  position: relative;
  display: flex;
  gap: 0px;
  align-items: center;

  .swiper-button-prev, .swiper-button-next {
    min-width: 48px;
    height: 48px;
    margin-top: 0;
    position: static;
  }

  .swiper-button-prev {
    justify-content: flex-start;
    left: 0;

    &:after {
      content: url("../img/swiper-left.svg");
      font-size: initial;
    }
  }

  .swiper-button-next {
    justify-content: flex-end;
    right: 0;

    &:after {
      content: url("../img/swiper-right.svg");
      font-size: initial;
    }
  }

  .swiper-scrollbar {
    width: 100%;
    background: rgba(128, 112, 77, 0.2);
  }

  .swiper-scrollbar-drag {
    height: 8px;
    height: 8px;
    background: $main3;
    cursor: pointer;
  }
}


.partners {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;

  padding-left: 30px;
  border-left: 4px solid rgba(128, 112, 77, 0.1);

  img {
    max-width: 180px;
    max-height: 100px;
    object-fit: contain;
  }

  p{
    font-size:$font14;
    margin-top:15px;
    margin-bottom:15px;

  }
}

.partners--2 {
  gap: 30px;
  justify-content: space-between;
  max-width: 560px;
}

.partners--3 {
  min-height: 220px;
}

.save {
  background: $bg-grey;
  overflow: hidden;
  padding-bottom: 60px;
  position: relative;
}

.save__wrapper {
  display: flex;

}

.save__left {
  flex: 0 0 50%;
  max-width: 50%;

  .alert {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: $main3;
    margin-top: 20px;
  }
}

.save__img {
  position: absolute;
  bottom: 0px;
  left: 60%;
  height: 100%;
  width: auto;

}

.save__form .form__input {
  max-width: 480px;
}

.contact {
  h3 {
    font-size: $font18;
  }

  a {
    color: $txt;
    @media(hover: hover) {
      &:hover {
        color: $main3;
      }
    }
  }
}


.map__wrapper {
  iframe {
    max-width: 100%;
  }
}

.file__wrapper {
  position: relative;
  border-right: 4px solid rgba(128, 112, 77, 0.1);
  margin-bottom: 40px;
}

.goToTop {
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  right: -50px;
  bottom: 55px;
  color: $main;
  font-weight: 500;
  transform: rotate(-90deg);
  border: none;
  background: transparent;


  &:after {
    content: url("../img/scroll-down.svg");
    transform: rotate(-90deg);
    display: inline-block;
    position: relative;
    left: 8px;
  }
}



@media(max-width: $grid-breakpoints-xxl) {
  .modal .speaker__name {
    font-size: 36px;

    strong {
      font-size: 40px;
    }
  }
}
@media(max-width: $grid-breakpoints-xl) {
  .speaker__list {
    gap: 12px;
  }
  .speaker__item {
    max-width: calc(25% - 9px);
    flex: 0 0 25%;
  }

}


@media(max-width: $grid-breakpoints-lg) {
  .speaker__list {
    gap: 18px;
  }
  .speaker__item {
    max-width: calc(33.33% - 12px);
    flex: 0 0 33.33%;
  }

  .partners--2 {
    justify-content: flex-start;
  }


}

@media(max-width: $grid-breakpoints-md) {

  .modal .speaker__name {
    font-size: 28px;

    strong {
      font-size: 34px;
    }
  }
  .partners {
    gap: 20px;

    img {
      max-height: 40px;
    }
  }
  .partners--3 {
    min-height: auto;
  }

}

@media(max-width: $grid-breakpoints-sm) {


  .save__wrapper {
    display: block;
  }
  .save__left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .save__img {
    position: absolute;
    height: auto;
    width: 580px;
    left: 0;
    bottom: 0;
  }

  .save__figure {
    position: relative;
    height: 250px;
    overflow: hidden;
    width: 100vw;
    left: -40px;
    top: 60px;
    @media(max-width: $grid-breakpoints-xs) {
      left: -20px;
    }
  }


  .speaker__list {
    gap: 16px;
  }
  .speaker__item {
    max-width: calc(50% - 8px);
    flex: 0 0 50%;
  }

  .speaker__bottom {
    padding: 10px;
  }


  .schedule__title {
    display: block;
  }

  .title__data {
    margin-top: 20px;
    display: flex;
    width:fit-content;

    >first-child {
      font-size: 40px;
    }
  }
}

.conference__list {
  padding-left: 0;
  list-style: none;

  h3 {
    font-weight: 300;
    font-size:$font18;
    strong{
      display:block;
      margin-top:15px;
      font-size:$font22;
    }
  }

  > li {
    border-bottom: 3px solid rgba(128, 112, 77, 0.1);
    padding-top: 25px;
    padding-bottom: 25px;

  }

  ul {
    padding-left: 30px;
    list-style: disc;
    color:$main;
  }
}