.modal-open{
  overflow:hidden;
  height:100vh;

  .section{
    z-index:0 !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;

  &.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .link--underline-main {
    display: block;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 120;
    background-color: rgba(230, 226, 219, 0.9);
  }

  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    outline: none;
    background: none;
    width: 160px;
    height: 60px;
    display: inline-block;
    background: #fff;
    color: $main;
    padding: 0 70px 0 0;
    text-align: right;
    text-transform: uppercase;
    color: $main3;
    font-size:14px;
    overflow: hidden;

    &:after {
      content: "+";

      transform: rotate(45deg);
      font-size: 50px;
      position: absolute;
      top: -2px;
      right: 20px;
      transition: all 0.3s ease-out;
      font-weight: 300;

    }

    &:hover {
      background: $bg-grey;
    }
  }

  .modal__content {
    width: 100%;
    overflow: auto;
    max-height: 80vh;
  }

  &__container {
    position: relative;
    z-index: 122;
    max-width: 1320px;
    width: 96%;
    top: 5%;
    margin: 0 auto;
    padding: 50px 100px 70px 100px;
    background-color: #fff;
    overflow: auto;


    @media(max-width: 1200px) {
      padding: 50px 65px;
    }

    @media(max-width: $grid-breakpoints-lg) {
      padding: 50px 20px 20px 20px;
      height: auto;
      max-height: 95%;
    }

    @media(max-width: $grid-breakpoints-sm) {
      padding: 40px 20px 20px 20px;
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 15px;
  }

  .speaker__name {
    font-size: 46px;

    strong {
      font-size: 60px;
    }
  }

  .speaker-details__txt {
    padding-top: 30px;
  }

  .row--60 [class*=col-] {
    margin-bottom: 0px;
  }

  .speaker-details__img {
    max-width: 100%;

    img {
      width: 100%;
    }
  }

  h4 {
    font-size: 31px;
    margin-bottom: 10px;
    color: #000;
  }

  @media(max-width: $grid-breakpoints-lg) {
    &__title {

      font-size: 24px;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 5px;
    }


  }

  ul {
    list-style: disc;
    padding-left: 30px;
  }

  @media(max-width: $grid-breakpoints-sm) {
    &__container {
      width: 100%;
      top: 0%;
      padding-top: 60px;
      max-height: 100vh;
    }
    &__close {
      position: fixed;
      width: 100%;
    }
  }

}
