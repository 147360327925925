/* lato-300 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'museo-slab';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Museo_Slab_300.otf'); /* IE9 Compat Modes */
}

/* lato-300italic - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'museo-slab';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Museo_Slab_300italic.otf'); /* IE9 Compat Modes */

}

/* lato-regular - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'museo-slab';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Museo_Slab_500.otf');
}

/* lato-italic - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Museo_Slab_500italic.otf');
}