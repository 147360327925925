
.hero__outer {
  @media(min-width: 1520px) {
    max-width: calc(100% - 80px);
    .hero__wrapper {
      max-width: calc(100% - 120px);
    }

  }
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 21;


  .container {
    position: relative;
    z-index: 4;
  }

}

.hero__outer--2 .hero__wrapper {
  min-height: 400px;
  display: flex;
  align-items: center;

}

.hero {

  position: relative;
  padding-top: 90px;
  padding-bottom: 80px;
  overflow: hidden;

  &:before {
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    content: "";
    display: block;
    border: 5px solid $main;
    top: 40px;
    left: 40px;
    z-index: 2;
    position: absolute;
  }

  .back {
    position: absolute;
    right: 40px;
    top: 0px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    position: absolute;

    &:before {
      content: url("../img/back.svg");
      margin-right: 10px;
    }

  }

}

@keyframes animateMainImg {
  0% {
    width: 105%;
  }
  100% {
    width: 115%;
  }
}

@keyframes animateMainImgMobile {
  0% {
    height: 105%;
  }
  100% {
    height: 115%;
  }
}

.hero__img {
  position: absolute;
  top: -70px;
  left: 0;
  width: 110%;
  height:auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition: all 0.3s ease-out;
  animation-name: animateMainImg;
  animation-fill-mode: forwards;
  animation-duration: 10s;

  @media(max-width: 1400px) {
    top: 0;
    height: 100%;
    width: auto;
    animation-name: animateMainImgMobile;
  }
}

.hero__outer--2{
  .hero__img {
    top:-70px;
    animation-name: animateMainImg;
    width: 110%;
    height:auto;
    @media(max-width: 1000px) {
      top: 0;
      height: 100%;
      width: auto;
      animation-name: animateMainImgMobile;
    }
  }
}

.hero__txt {
  color: #fff;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: 1200px) {
    min-height: 500px;
  }


  h1 {
    position: relative;
    margin-top: 80px;
    margin-bottom: 120px;
    font-size: 50px;
    display: inline-block;
    width: fit-content;
    line-height: 1.2;
    padding-left: 20px;

    span {
      color: #C7AF87;
      font-weight: 400;
    }


    &:before, &:after {
      display: block;
      width: 30px;
      height: 30px;
      content: "";
      position: absolute;

    }

    &:before {
      border-top: 5px solid $main;
      border-left: 5px solid $main;
      top: -15px;
      left: 0px;
    }

    &:after {
      border-bottom: 5px solid $main;
      border-right: 5px solid $main;
      bottom: -15px;
      left: 340px;
    }
  }

}

.body-en {
  .hero__txt h1:after {
    left: 380px;
  }
}

.hero__txt--2 {
  min-height: initial;
  justify-content: center;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero__txt1 {
  font-size: $font27;
  display: block;
}

.hero__data {
  display: block;
  font-size: 40px;
}


.hero__bottom {
  background: $bg-grey2;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media(min-width: 1520px) {

    .container {
      max-width: calc(100% - 80px);
      padding-left: 60px;
      padding-right: 60px;
    }

  }

}

.hero__bottom--2 {
  background-image: linear-gradient(to right, #fff, #fff 56%, $bg-grey 56%, $bg-grey 100%);

  position: relative;

  .hero__bottom--img2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 56%;

  }

  .container {
    position: static;
    justify-content: flex-end;
  }

  #scrollDown {
    right: 44%;
    font-size: 10px;
    border: none;
    bottom: 20px;
  }
}


.hero__bottom--quote {
  font-style: italic;
  font-size: $font27;
  text-align: right;

  small {
    font-size: 70%;
  }
}

.hero__bottom--img {
  max-width: 30%;
}

#scrollDown {
  position: absolute;
  bottom: 45px;
  right: 630px;
  z-index: 20;
  color: #C7AF87;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-size: 13px;
  font-weight: 500;
  transform: rotate(-90deg);
  background: none;
  border: none;
  transition: none;
  border-bottom: 1px solid rgba(93, 93, 93, 0.5);
  padding-left: 0;
  padding-right: 10px;

  &:before {
    content: url("../img/scroll-down.svg");
    transform: rotate(90deg);
    width: 10px;
  }

  @media(max-width: 1520px) {
    right: 600px;
  }
}


@media(max-width: 1700px) {
  .hero__bottom--2 {
    background-image: linear-gradient(to right, #fff, #fff 48%, #f2f0ed 48%, #f2f0ed 100%);

    .hero__bottom--img2 {
      width: 48%;
    }

    #scrollDown {
      right: 51%;
    }
  }
}

@media(max-width: $grid-breakpoints-xxl) {
  .container.hero__wrapper {
    max-width: calc(100% - 80px);
  }
}


@media(max-width: $grid-breakpoints-xl) {
  .hero__bottom--2 {
    background-image: linear-gradient(to right, #fff, #fff 38%, #f2f0ed 38%, #f2f0ed 100%);

    .hero__bottom--img2 {
      width: 38%;
    }

    #scrollDown {
      right: 60%;
    }
  }
}

@media(max-width: $grid-breakpoints-lg) {

  .hero {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .hero__bottom--quote {
    font-size: 23px;
    max-width: 60%;

  }

  #scrollDown {
    right: 57%;
  }
}


@media(max-width: $grid-breakpoints-md) {
  .hero__bottom {
    .container {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .hero__bottom--quote {
    max-width: 100%;

    font-size: 13px;
    padding-left: 30px;
  }

  .hero__bottom--2 {
    background: $bg-grey;

    .hero__bottom--img2 {
      width: 100%;
      position: static;
    }

    #scrollDown {
      right: initial;
      left: -10px;
      bottom: -15px;
    }
  }

  .hero__txt h1 {
    margin-top: 170px;
    margin-bottom: 20px;
  }

  #scrollDown {
    right: initial;
    left: 5px;
    bottom: -20px;
    font-size: 9px;
    border-bottom: none;
  }

  .hero__txt {
    min-height: auto;
  }

  .hero {
    overflow: hidden;

    &:after {
      width: 100%;
      height: 50%;
      content: "";
      display: block;
      bottom: 0;
      left: 0px;
      z-index: 1;
      position: absolute;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    }

  }

  .hero__img {
    height: 120%;
    width: auto;
    left: initial;
    right: -200px;
    transform: none;
  }
  .hero__txt1 {
    font-size: 14px;
  }
  .hero__txt h1 {
    font-size: 34px;
  }
  .hero__data {

    font-size: 16px;
  }

  .hero__txt h1:after {
    left: 240px;
  }

  .body-en .hero__txt h1:after {
    left: 260px;
  }

}


@media(max-width: $grid-breakpoints-sm) {


  .hero__outer--2 .hero__wrapper {
    min-height: 200px;
  }

  .hero__img {
    height: 100%;
    right: -260px;
  }

  .hero__outer--2{
    .hero__img {
      right: -100px;
    }
  }


}

@media(max-width: $grid-breakpoints-xs) {
  .hero__txt h1 {
    font-size: 30px;
  }

  .hero .back {
    right: 20px;
  }
  .hero:before {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 20px;
    left: 20px;
  }

  .hero {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container.hero__wrapper {
    max-width: calc(100% - 40px);
  }

  .body-en .hero__txt h1:after {
    left: 230px;
  }
}