.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;

  &:before {
    content: "" !important;
    width: 50px;
    height: 50px;
    background: url("../img/swiper-arrow.svg") center no-repeat;
    background-size: 16px;
    display: block;
  }
}

.link--underline {
  text-decoration: underline;
  color: $txt;

  @media(hover: hover) {
    &:hover {
      color: $main2;
    }
  }
}

.link--underline-main {
  text-decoration: underline;
  color: $main;

  @media(hover: hover) {
    &:hover {
      color: $main2;
    }
  }
}


.btn {
  border: none;
  background-color: $main3;
  color: #fff !important;
  font-size: $font18;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  transition: background 0.3s ease-out;

  line-height: 1;
  padding: 14px 20px;
  vertical-align: middle;
  text-align: center;
  border-radius: 0;
  border: 3px solid $main3;


  @media(hover: hover) {
    &:hover {
     border-color: #C7AF87;

      color: #fff !important
    }
  }
}


.btn--arrow {
  padding-right: 70px;
  text-align: left;

  &:after {
    content: url("../img/arrow-right.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    width: 18px;
    display: inline-block;
  }

  @media(max-width: $grid-breakpoints-md) {
    padding-right: 60px;
    &:after {
      right: 25px;

    }
  }
}


.btn--red {
  background: $main2;
  color: #fff !important;
  border-color:$main2;
  @media(hover: hover) {
    &:hover {
      border-color: $main2-hover;
      color: #fff !important
    }
  }
}