.footer {

  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background: $bg-grey;
  @media(min-width: 1520px) {
    max-width: calc(100% - 80px)
  }
  margin-left: auto;
  margin-right: auto;


  @media(min-width: $grid-breakpoints-xxl) {
    margin-bottom: 30px;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  font-size: $font14;
  align-items: center;
  position: relative;
  z-index: 10;


}

.footer__ddLink {
  padding: 15px 10px;
  text-decoration: underline;
  color: $txt;
}


.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 15px;
  padding-top: 15px;
  line-height: 1;

  span {
    display: inline-block;
    padding: 2px;
    vertical-align: bottom;
    line-height: 1;
  }

  a {
    display: inline-block;
    padding: 5px;
    vertical-align: bottom;
    line-height: 1;
  }

  img {
    max-height: 40px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .footer__bottom {
    flex-direction: column;
    gap: 5px;
  }
}