@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.menu-is-fixed {
  padding-top: 190px;
}


.header {

  background: #fff;
  position: static;
  z-index: 90;
  width: 100%;
  padding-top: 20px;

  &.fixed-header-scroll {
    position: fixed;
    top: 0;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    padding-top: 10px;


    .header__top {
      height: 30px;
    }

    .header__bottom {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .btn--red{
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }


}

.modal-open {
  .fixed-header-scroll {
    z-index: -1;
  }
}

.header__top {
  display: flex;
  justify-content: space-between;
  height: 70px;

  a {
    display: block;
    height: 100%;
  }

  img {
    width: auto;
    max-height: 100%;
    display: block;

  }

  .header__logo2 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

}

.lang__list {
  display: flex;
  padding-left: 15px;
  margin-left: 10px;
  border-left: 1px solid $line;

  img {
    width: 24px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    font-size: $font18;
    font-weight: 300;
    line-height: 1.6;
    color: $txt-dark;

    &.active {
      font-weight: 700;
    }

    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }


}


.header__wrapper {
  position: relative;

  &.container {
    @media(min-width: 1520px) {
      max-width: calc(100% - 80px);
      padding-left:0;
      padding-right:0;
    }
  }
}

.headerForm {
  width: 48px;
  height: 48px;
  position: relative;
  margin-left: 40px;
  margin-right: auto;


  input {
    border: none;
    width: 100%;
    padding-right: 40px;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #fff;
    border-radius: 25px;
    font-weight: 600;
    min-height: 50px;
    border: 2px solid $line;

  }

  ::placeholder {
    color: $txt;
    font-weight: 600;
  }

  button {
    position: absolute;
    width: 48px;
    right: 0px;
    bottom: 0px;
    border: none;
    padding: 0;
    display: inline-block;
    background: transparent;
    height: 48px;
    background-image: url("../img/ico-search-main.svg");
    background-repeat: no-repeat;
    background-position: center;

  }
}


.header__accesibility {
  border: 1px solid $line;
  border-radius: 25px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 30px;
  overflow: hidden;
  margin-left: auto;

  li {
    height: 48px;
  }

  a,
  button {
    display: inline-block;
    height: 100%;
    min-width: 48px;
    height: 48px;
    padding: 0;
    background-color: transparent;
  }
}


.link--add {
  background-image: url("../img/ico-add.svg");
  background-repeat: no-repeat;
  background-position: center;

}

.btn--contrast {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico-contrast.svg");
  border: none;
  vertical-align: middle;

  span {
    min-width: 210px;
  }

}


.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: $font20;
  left: -10px;
  line-height: 50px;

  strong {
    margin-left: -20px;
  }


  span {
    width: 26px;
    height: 2px;
    background: #000;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(3) {
      top: 35px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
    }
  }

}


.header__wrapper {
  //background: $main;
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  padding-top: 15px;
  padding-bottom: 15px;

  .btn--red {
    margin-left: 20px;
  }

}

.nav > ul {
  display: flex;


  > li.active > a {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
    text-decoration-color: $main2;
  }

  > li > a {
    font-weight: 500;
    font-size: 18px;
    color: $txt-dark;
    display: inline-block;
    padding: 10px 15px 10px 15px;

    @media(hover: hover) {
      &:hover {
        color: $main2 !important;
      }
    }
  }


  @media(min-width: $grid-breakpoints-xl) {
    > li:first-child > a {
      padding-left: 0;
    }
  }

  a {
    transition-property: color;
  }

}


.header__social {
  display: flex;
  align-items: center;
  margin-left: auto;

  a {
    display: inline-flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }
}


.page__header {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  overflow: hidden;
  background: $main;

  @media(max-width: $grid-breakpoints-sm) {
    min-height: 200px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.15;
  }

  .breadcrumbs {
    border: none;
  }

  .container {
    position: relative;
    z-index: 1;
    text-align: center;

  }

  h1,
  h2 {
    font-size: $font40;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;

    @media(max-width: $grid-breakpoints-sm) {
      font-size: $font34;
    }

  }

  .breadcrumbs__list {
    justify-content: center;
  }

  .breadcrumb__items {

    span {
      color: #fff;

      &:before {
        color: #fff;
      }
    }

    a {
      color: #fff;

      &:before {
        color: #fff;
      }

      @media(hover: hover) {
        &:hover {
          color: $yellow;
        }
      }
    }


  }
}


header > ul > li {
  position: relative;
}


.menu__item--has-children {
  ul {
    display: none;
  }
}


@media(max-width: $grid-breakpoints-xl) {
  .header {
    padding-top: 0px;
    position: relative;
    z-index: 100;
  }

  .menu-open .header {
    transition: none;
  }

  .header {
    transition: background 0s ease-out 0.3s;
  }
  .header__wrapper {
    flex-wrap: nowrap;
    height: 120px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
  }
  .nav {
    margin-left: 0
  }
  .header__top {
    height: 35px;
  }

  .header__bottom {
    margin-top: 10px;
    width: 100%;
    height: 70px;
    border-top: 1px solid $line;
    padding-top: 0;
    padding-bottom: 0;

  }


  .nav .nav__wrapper {
    // display: none;
    position: absolute;
    top: 100%;
    flex-direction: column;
    background: #fff;
    width: calc(100% + 80px);
    left: -40px;
    max-height: 0;
    height: 100vh;
    overflow: hidden;
    margin-top: 0;
    z-index: 100;
    transition: all 0.3s ease-out;
    background: rgba(230, 226, 219, .91);


    li {
      background: #fff;
      padding-left: 40px;

    }

    a {
      font-size: 26px;
      border-left: 4px solid rgba(128, 112, 77, .1);
      width:100%;
    }

    &.open {
      max-height: calc(100vh - 120px);
      overflow: auto;
    }

    > li:first-child {
      padding-top: 15px;
    }

    > li:last-child {
      padding-bottom: 25px;
    }
  }


  .nav-toggle {
    display: block;

  }

  .header.fixed-header-scroll {
    padding-top: 0;

    .header__top {
      height: 25px;
    }

    .header__wrapper {
      height: 75px;
    }

    .header__bottom {
      margin-top: 5px;
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;

      .btn--red {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }


}


.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}


.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

@media(max-width: $grid-breakpoints-sm) {
  .header__top {
    height: 25px;
  }


  .nav > ul > li > a {
    text-align: left;
  }
  .header__wrapper {
    height: 100px;
  }
  .header__bottom {
    height: 60px;

    .btn--red {
      margin-left: 0;
      font-size: 13px;
      padding-left: 10px;
      padding-right: 10px;

      span {
        display: none;
        display: none;
      }
    }
  }

  .lang__list {
    padding-left: 5px;
    margin-left: 0px;
  }
}

@media(max-width: $grid-breakpoints-xs) {
  .nav .nav__wrapper {
    width: calc(100% + 40px);
    left: -20px;
  }
}

@media(max-width: 400px) {
  .header__top {
    height: 25px;
  }
}

