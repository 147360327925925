.file__list {
  padding-bottom: 20px;
  padding-right:25px;
}

.file__link {
  display: flex;
  align-items: center;
  width: 100%;
  background-image: url("../img/ico-download.svg");
  background-position: left center;
  background-repeat: no-repeat;


  padding: 8px 15px;
  padding-left: 30px;
  color: $main;
  font-weight:700;
  gap: 20px;
  font-size: $font18;

  @media(hover:hover) {
    &:hover {
      color: $main2;

        text-decoration: underline;

    }
  }

}
