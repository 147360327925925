.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.container {
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $container-max-width-xxl;

  @media (max-width: $grid-breakpoints-xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
  //@media (min-width: $grid-breakpoints-lg) {
  //  max-width: $container-max-width-lg;
  //
  //  &.container--900 {
  //    max-width: 900px;
  //  }
  //}
  //@media (min-width: $grid-breakpoints-xl) {
  //  max-width: $container-max-width-xl;
  //}
  //@media (min-width: $grid-breakpoints-xxl) {
  //  max-width: $container-max-width-xxl;
  //}
}


@media (min-width: $grid-breakpoints-xl) {
  .container--md {
    max-width: 1000px;
  }

}

.container--500 {
  max-width: $container-max-width-sm;
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);

  @media (max-width: $grid-breakpoints-sm) {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }
}

[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: $grid-breakpoints-sm) {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
}


.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);

  [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    margin-bottom: 16px;
  }
}

.row--60 {
  @media(min-width: $grid-breakpoints-xl) {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);

    [class*="col-"] {
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: $grid-breakpoints-sm) {

  .col-sm-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }
  .col-sm-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}


@media (min-width: $grid-breakpoints-md) {
  .col-md-3 {
    width: 25%;
    flex: 0 0 25%;
  }
  .col-md-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }
  .col-md-50,
  .col-md-6 {
    width: 50%;
    flex: 0 0 50%;
  }
  .col-md-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }
  .col-md-9 {
    width: 75%;
    flex: 0 0 75%;
  }

}


@media (min-width: $grid-breakpoints-lg) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }
  .col-lg-3 {
    width: 25%;
    flex: 0 0 25%;
  }
  .col-lg-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }
  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-lg-8 {
    max-width: 66.6%;
    flex: 0 0 66.6%;
  }
}

@media (min-width: $grid-breakpoints-xl) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    width: 20%;
    flex: 0 0 20%;
  }
}


.section--2 {
  padding-top: 80px;
  padding-bottom: 80px;

  @media(max-width: $grid-breakpoints-md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section {
  margin-top: -60px;
  padding-top: 110px;
  padding-bottom: 20px;
  background: #fff;
  @media(min-width: 1520px) {
    max-width: calc(100% - 80px)
  }
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 20;

  &:nth-of-type(2) {
    z-index: 19;
  }

  &:nth-of-type(3) {
    z-index: 18;
  }

  &:nth-of-type(4) {
    z-index: 17;
  }

  &:nth-of-type(5) {
    z-index: 16;
  }

  &:nth-of-type(6) {
    z-index: 15;
  }

  &:nth-of-type(7) {
    z-index: 14;
  }

  &:nth-of-type(8) {
    z-index: 13;
  }

  &:nth-of-type(9) {
    z-index: 12;
  }

  &:nth-of-type(10) {
    z-index: 11;
  }

  &:nth-of-type(11) {
    z-index: 10;
  }

  // @media(max-width:$grid-breakpoints-md) {
  //     padding-top: 60px;
  //     padding-bottom: 60px;
  // }

}

.section--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ml-auto {
  margin-left: auto !important;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;

  @media(max-width: $grid-breakpoints-md) {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
  @media(max-width: $grid-breakpoints-md) {
    margin-bottom: 60px !important;
  }
}