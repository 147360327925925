.menu-is-fixed .settings__outer {
  display: none;
}

.settings__outer {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.dd__link {
  border: none;
  background: transparent;
  display: none;
  transition: none;
}
.dd__link.open {
  background: #F2F0ED;
  border: none;
}

.sr-title {
  opacity: 0;
  position: absolute;
  top: 100%;
  background: #fff;
  width: 60px;
  text-align: center;
  left: 25%;
  z-index: -1000;
  box-shadow: 5px 4px 11px -4px rgba(0, 0, 0, 0.16);
  display: block;
  transition: opacity 0.3s ease-in-out;
  font-size: 10px;
  padding: 5px;
}

.settings__wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  top: -1px;
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #F6F7FA;
  border-top: none;
  transition: top 0.3s ease-in-out;
  padding-left: 5px;
}
.settings__wrapper button {
  background: none;
  border: none;
}
.settings__wrapper ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  height: 30px;
}
.settings__wrapper a,
.settings__wrapper button {
  position: relative;
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px;
}
.settings__wrapper a:hover .sr-title, .settings__wrapper a:focus .sr-title,
.settings__wrapper button:hover .sr-title,
.settings__wrapper button:focus .sr-title {
  z-index: 10;
  opacity: 1;
}
.settings__wrapper a[aria-pressed=true],
.settings__wrapper button[aria-pressed=true] {
  background: #B19153;
  color: #fff;
}
.settings__wrapper .dd__link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.menu-is-fixed .settings__wrapper {
  top: 0;
}

.settings__option--l a,
.settings__option--l button {
  width: 22px;
  height: 22px;
  margin-right: 0;
  margin-left: 0;
  color: #000;
}

.settings__option {
  display: flex;
  align-items: center;
  border-right: 1px solid #F2F0ED;
  padding-right: 5px;
}

.settings__t1 {
  font-size: 0.75rem;
  display: inline-block;
  padding-left: 5px;
}
@media (max-width: 768px) {
  .settings__t1 {
    display: none;
  }
}

.settings__t2 {
  font-size: 0.75rem;
  font-weight: 700;
  display: inline-block;
  padding-left: 10px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .settings__t2 {
    width: 0;
    opacity: 0;
    z-index: -1000;
    overflow: hidden;
    position: absolute;
  }
}

.settings__a--d {
  font-size: 0.75rem;
}

.settings__a--w {
  font-size: 0.9375rem;
  font-weight: 700;
}

.settings__a--n {
  font-size: 1.25rem;
  font-weight: 700;
}

.settings__wrapper .settings__link--reset:before {
  content: url("../img/s-icon-reset.svg");
}
.settings__wrapper .settings__link--nc:before {
  content: url("../img/s-icon-night.svg");
}
.settings__wrapper .settings__link--big:before {
  content: url("../img/s-icon-dark.svg");
}
.settings__wrapper .settings__link--swiper {
  width: 48px;
  height: 26px;
  border-radius: 13px;
  background: #fff;
  display: block;
  position: relative;
  margin-left: 5px;
}
.settings__wrapper .settings__link--swiper:before {
  position: absolute;
  transition: left 0.3s ease-out;
  top: 3px;
  left: 3px;
}

.settings__wrapper button.settings__link--swiper[aria-pressed=true]:before {
  left: 25px;
}

@media (max-width: 1700px) {
  .settings__outer {
    left: 60%;
  }
}
@media (max-width: 950px) {
  header .header__wrapper {
    padding-top: 40px;
  }

  .settings__outer {
    left: 50%;
  }

  .header.fixed-header-scroll .header__wrapper {
    padding-top: 5px;
  }
}
@media (max-width: 1200px) {
  header .header__wrapper {
    height: 155px;
  }
}
/* lato-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Museo_Slab_300.otf");
  /* IE9 Compat Modes */
}
/* lato-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Museo_Slab_300italic.otf");
  /* IE9 Compat Modes */
}
/* lato-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Museo_Slab_500.otf");
}
/* lato-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Museo_Slab_500italic.otf");
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
font,
img,
ins,
kbd,
s,
samp,
strong,
tt,
var,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #5D5D5D;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.container {
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1380px;
}
@media (max-width: 440px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .container--md {
    max-width: 1000px;
  }
}
.container--500 {
  max-width: 490px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
@media (max-width: 576px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }
}

[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  [class*=col-] {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 60px;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 768px) {
  .col-md-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-50,
.col-md-6 {
    width: 50%;
    flex: 0 0 50%;
  }

  .col-md-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-md-9 {
    width: 75%;
    flex: 0 0 75%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-8 {
    max-width: 66.6%;
    flex: 0 0 66.6%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    width: 20%;
    flex: 0 0 20%;
  }
}
.section--2 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .section--2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section {
  margin-top: -60px;
  padding-top: 110px;
  padding-bottom: 20px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 20;
}
@media (min-width: 1520px) {
  .section {
    max-width: calc(100% - 80px);
  }
}
.section:nth-of-type(2) {
  z-index: 19;
}
.section:nth-of-type(3) {
  z-index: 18;
}
.section:nth-of-type(4) {
  z-index: 17;
}
.section:nth-of-type(5) {
  z-index: 16;
}
.section:nth-of-type(6) {
  z-index: 15;
}
.section:nth-of-type(7) {
  z-index: 14;
}
.section:nth-of-type(8) {
  z-index: 13;
}
.section:nth-of-type(9) {
  z-index: 12;
}
.section:nth-of-type(10) {
  z-index: 11;
}
.section:nth-of-type(11) {
  z-index: 10;
}

.section--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ml-auto {
  margin-left: auto !important;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
@media (max-width: 768px) {
  .mb-100 {
    margin-bottom: 60px !important;
  }
}

/* lato-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Museo_Slab_300.otf");
  /* IE9 Compat Modes */
}
/* lato-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Museo_Slab_300italic.otf");
  /* IE9 Compat Modes */
}
/* lato-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "museo-slab";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Museo_Slab_500.otf");
}
/* lato-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Museo_Slab_500italic.otf");
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  html {
    font-size: 12px;
  }
}

body {
  line-height: 1.4;
  font-size: 1.125rem;
  font-family: "museo-slab", serif;
  font-weight: 300;
  color: #5D5D5D;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: "museo-slab", serif;
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip span {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  z-index: -1000;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 5px;
  font-size: 0.75rem;
  color: #5D5D5D;
  width: 0;
  height: 0;
  overflow: hidden;
  text-align: center;
  line-height: 1.2;
}
.tooltip:hover span {
  opacity: 1;
  z-index: 1;
  height: auto;
  width: auto;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 600 !important;
}

.font--9 {
  font-size: 9px !important;
}

.font--10 {
  font-size: 0.625rem !important;
}

.font--12 {
  font-size: 0.75rem !important;
}

.font--14 {
  font-size: 0.875rem !important;
}

.font--18 {
  font-size: 1.125rem !important;
}

.font--20 {
  font-size: 1.25rem !important;
}

.main {
  color: #B19153 !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #5D5D5D !important;
}

.main2 {
  color: #C61523 !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #C61523;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #B19153;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #80704D;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

h1,
h2,
h3 {
  letter-spacing: initial;
  line-height: 1.3;
  font-weight: 900;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

.title {
  font-size: 3.75rem;
  font-family: "museo-slab", serif;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
  color: #80704D;
  margin-bottom: 40px;
}

.title--small {
  font-size: 2.5rem;
}

.description {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 50px;
}

h4,
h5,
h6 {
  line-height: 1.3;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.list--own ul {
  padding-left: 20px;
  list-style: none;
}
.list--own ul li {
  position: relative;
  margin-bottom: 5px;
}
.list--own ul li:before {
  content: ">";
  position: absolute;
  top: 0px;
  left: -20px;
  font-weight: 700;
}
.list--own ul li ul {
  padding-left: 15px;
}
.list--own ul li li:before {
  top: -8px;
  left: -13px;
  font-size: 20px;
  content: ".";
  font-weight: 700;
}

@media (max-width: 576px) {
  .description {
    font-size: 1.125rem;
  }

  h1,
.title {
    font-size: 2.5rem;
    margin-bottom: 25px;
  }

  h2 {
    font-size: 1.625rem;
  }

  h4,
h5,
h6 {
    font-size: 1rem;
  }

  .description {
    margin-bottom: 30px;
  }
}
ul,
ol {
  list-style: none;
}

.page__txt {
  line-height: 1.6;
}
.page__txt img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  height: auto;
}
.page__txt ul,
.page__txt ol {
  padding-left: 30px;
}
.page__txt ul {
  list-style: initial;
}
.page__txt ol {
  list-style: decimal;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
.page__txt h2:not(:first-child) {
  margin-top: 35px;
}
.page__txt h3:not(:first-child) {
  margin-top: 25px;
}

input:not([type=date], [type=number], [type=radio], [type=checkbox]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "museo-slab", serif;
}

input,
textarea,
select {
  border: 1px solid #E3E3E3;
  line-height: 1.6;
  padding: 10px 15px;
  color: #5D5D5D;
  font-family: "museo-slab", serif;
  font-size: 1rem;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  font-family: "museo-slab", serif;
  font-weight: 300;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  font-family: "museo-slab", serif;
  font-weight: 300;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "museo-slab", serif;
  font-weight: 300;
}

::-moz-placeholder {
  color: #5D5D5D;
  font-weight: 300;
  font-style: inherit;
  font-family: "museo-slab", serif;
}

:-ms-input-placeholder {
  color: #5D5D5D;
  font-weight: 300;
  font-style: inherit;
  font-family: "museo-slab", serif;
}

::placeholder {
  color: #5D5D5D;
  font-weight: 300;
  font-style: inherit;
  font-family: "museo-slab", serif;
}

.disabled {
  color: #5D5D5D;
}

hr {
  border: none;
  border-top: 1px solid #F2F0ED;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  max-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;
}
table * {
  line-height: 1.4;
}
table th {
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #F2F0ED;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border-bottom: 1px solid #F2F0ED;
  padding: 10px 10px;
  vertical-align: middle;
}
@media (min-width: 1400px) {
  table {
    font-size: 1rem;
  }
}
table thead {
  position: relative;
}
table thead th {
  border: none;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid #C61523;
  border-bottom: 2px solid #C61523;
}
table .align-right {
  text-align: right;
}
table .align-left {
  text-align: left;
}
table .align-center {
  text-align: center;
}
table tbody tr:first-child th,
table tbody tr:first-child td {
  border-top-width: 5px;
}

.table__wrapper {
  position: relative;
}
.table__wrapper table {
  width: 100%;
  position: relative;
}
.table__wrapper table a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 800px;
    width: initial;
  }
  .table__wrapper table th,
.table__wrapper table td {
    padding: 8px 5px;
  }
  .table__wrapper.table__wrapper--big table {
    min-width: 1300px;
    width: initial;
  }
}

.btn--table {
  background: #B19153;
  color: #fff;
  padding: 5px 10px !important;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #C61523;
    color: #fff;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.disabled {
  color: #5D5D5D;
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 0.625rem;
  max-height: 0;
  overflow: hidden;
  color: #6C6C6C;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #5D5D5D;
  font-size: 0.75rem;
  margin-bottom: 6px;
  font-weight: 700;
  display: block;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E3E3E3;
  border-radius: 0;
  padding: 12px 45px 12px 15px;
  display: block;
  background: transparent;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  outline: 2px solid #000;
  border-color: #000;
  box-shadow: none;
  outline-offset: 0;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 1rem;
  color: #5D5D5D;
  line-height: 40px;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 15px;
  bottom: 20px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 3px solid #5D5D5D;
  border-bottom: 3px solid #5D5D5D;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.is--desktop select {
  display: none;
  /*hide original SELECT element: */
}

.custom-select {
  position: relative;
  z-index: 40;
}
.custom-select .select-selected {
  color: #5D5D5D;
  border-radius: 0;
  padding: 12px 45px 12px 15px;
  border: 1px solid #E3E3E3;
  display: block;
  background: transparent;
  width: 100%;
  font-weight: 700;
  font-size: 1.125rem;
}
.custom-select .select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0;
  border: 1px solid #E3E3E3;
  overflow: hidden;
}
.custom-select .select-items.select-hide {
  display: none;
}
.custom-select .select-items div {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1.125rem;
}
@media (hover: hover) {
  .custom-select .select-items div:hover {
    background-color: #B19153;
    color: #fff;
  }
}
.custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.form__checkbox {
  margin-bottom: 0px;
  position: relative;
}
.form__checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  z-index: -1000;
  opacity: 0;
  width: 0;
  height: 0;
}
.form__checkbox a {
  text-decoration: underline;
  font-weight: 500;
}
.form__checkbox label {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 34px;
  line-height: 20px;
  color: #5D5D5D;
  font-size: 1.125rem;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.form__checkbox label:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 0;
  display: block;
  border: 2px solid #E3E3E3;
  background-color: #fff;
  transition: all 0.3s ease-out;
  border-radius: 0px;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 19px;
  left: 7px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #B19153;
  border-color: #B19153;
}
.form__checkbox input:checked ~ label:after {
  width: 12px;
  height: 7px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #B19153;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #B19153;
  top: 4px;
  left: 5px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #C61523;
}

.form__checkbox--small label {
  font-size: 0.75rem;
}

.form__checkbox--medium label {
  font-size: 1rem;
}

.input--data {
  background: url("../img/ico/ico-date-input.svg") right 15px center no-repeat;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input label {
  font-size: 0.625rem;
  margin-bottom: 6px;
  font-weight: 300;
  color: #5D5D5D;
  display: block;
  position: absolute;
  background: #fff;
  padding: 0 5px;
  top: 10px;
  z-index: -1;
  opacity: 0;
  transition: top 0.3s ease-out;
  transition: top 0.3s ease-out;
}
.form__input textarea {
  width: 100% !important;
}
.form__input input,
.form__input textarea {
  border-radius: 0;
  border: 1px solid #E3E3E3;
  width: 100%;
  display: block;
  color: #5D5D5D;
  font-size: 1.125rem;
  padding: 12px 15px;
  min-height: 54px;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  border-color: #E3E3E3;
  box-shadow: none;
  box-shadow: inset 0px 0px 0px 1px #E3E3E3;
  outline: none;
}
.form__input input:focus::-moz-placeholder, .form__input input:focus-visible::-moz-placeholder, .form__input textarea:focus::-moz-placeholder, .form__input textarea:focus-visible::-moz-placeholder {
  opacity: 0;
}
.form__input input:focus:-ms-input-placeholder, .form__input input:focus-visible:-ms-input-placeholder, .form__input textarea:focus:-ms-input-placeholder, .form__input textarea:focus-visible:-ms-input-placeholder {
  opacity: 0;
}
.form__input input:focus::placeholder, .form__input input:focus-visible::placeholder,
.form__input textarea:focus::placeholder,
.form__input textarea:focus-visible::placeholder {
  opacity: 0;
}
.form__input input:not(:-moz-placeholder-shown) ~ label, .form__input textarea:not(:-moz-placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:not(:-ms-input-placeholder) ~ label, .form__input textarea:not(:-ms-input-placeholder) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:focus ~ label, .form__input input:not(:placeholder-shown) ~ label,
.form__input textarea:focus ~ label, .form__input textarea:not(:placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input.is--valid {
  border-color: #3B8245 !important;
  background-image: none;
}
.form__input.is--valid:focus, .form__input.is--valid:focus-visible {
  box-shadow: inset 0px 0px 0px 1px #3B8245;
  border-color: #3B8245;
}
.form__input.is--invalid label {
  color: #C61523;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #C61523 !important;
  background-image: none;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  box-shadow: inset 0px 0px 0px 1px #C61523;
  border-color: #C61523;
}
.form__input.is--invalid:after {
  content: "+";
  transform: rotate(45deg);
  color: #C61523;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 1.875rem;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #3B8245 !important;
  box-shadow: inset 0px 0px 0px 1px #3B8245;
  background-image: none;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #3B8245;
  border-bottom: 2px solid #3B8245;
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 12px;
  height: 8px;
}
@media (max-width: 992px) {
  .form__input input,
.form__input textarea {
    font-size: 1rem;
  }
}

.form__input--bold label {
  font-size: 1.125rem;
  color: #5D5D5D;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__nolabel.is--valid:after {
  top: 15px;
}
.form__nolabel.is--invalid:after {
  top: 7px;
}

.form__input--error {
  color: #C61523;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  position: relative;
  top: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background: #F2F0ED;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1520px) {
  .footer {
    max-width: calc(100% - 80px);
  }
}
@media (min-width: 1520px) {
  .footer {
    margin-bottom: 30px;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  align-items: center;
  position: relative;
  z-index: 10;
}

.footer__ddLink {
  padding: 15px 10px;
  text-decoration: underline;
  color: #5D5D5D;
}

.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 15px;
  padding-top: 15px;
  line-height: 1;
}
.footer__brand span {
  display: inline-block;
  padding: 2px;
  vertical-align: bottom;
  line-height: 1;
}
.footer__brand a {
  display: inline-block;
  padding: 5px;
  vertical-align: bottom;
  line-height: 1;
}
.footer__brand img {
  max-height: 40px;
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 992px) {
  .footer__bottom {
    flex-direction: column;
    gap: 5px;
  }
}
@-webkit-keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
.menu-is-fixed {
  padding-top: 190px;
}

.header {
  background: #fff;
  position: static;
  z-index: 90;
  width: 100%;
  padding-top: 20px;
}
.header.fixed-header-scroll {
  position: fixed;
  top: 0;
  -webkit-animation-name: fixedMenuDown;
  animation-name: fixedMenuDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding-top: 10px;
}
.header.fixed-header-scroll .header__top {
  height: 30px;
}
.header.fixed-header-scroll .header__bottom {
  padding-top: 0px;
  padding-bottom: 0px;
}
.header.fixed-header-scroll .btn--red {
  padding-top: 7px;
  padding-bottom: 7px;
}

.modal-open .fixed-header-scroll {
  z-index: -1;
}

.header__top {
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.header__top a {
  display: block;
  height: 100%;
}
.header__top img {
  width: auto;
  max-height: 100%;
  display: block;
}
.header__top .header__logo2 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.lang__list {
  display: flex;
  padding-left: 15px;
  margin-left: 10px;
  border-left: 1px solid #F2F0ED;
}
.lang__list img {
  width: 24px;
}
.lang__list a {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.6;
  color: #000000;
}
.lang__list a.active {
  font-weight: 700;
}
@media (hover: hover) {
  .lang__list a:hover {
    color: #B19153;
  }
}

.header__wrapper {
  position: relative;
}
@media (min-width: 1520px) {
  .header__wrapper.container {
    max-width: calc(100% - 80px);
    padding-left: 0;
    padding-right: 0;
  }
}

.headerForm {
  width: 48px;
  height: 48px;
  position: relative;
  margin-left: 40px;
  margin-right: auto;
}
.headerForm input {
  border: none;
  width: 100%;
  padding-right: 40px;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #fff;
  border-radius: 25px;
  font-weight: 600;
  min-height: 50px;
  border: 2px solid #F2F0ED;
}
.headerForm ::-moz-placeholder {
  color: #5D5D5D;
  font-weight: 600;
}
.headerForm :-ms-input-placeholder {
  color: #5D5D5D;
  font-weight: 600;
}
.headerForm ::placeholder {
  color: #5D5D5D;
  font-weight: 600;
}
.headerForm button {
  position: absolute;
  width: 48px;
  right: 0px;
  bottom: 0px;
  border: none;
  padding: 0;
  display: inline-block;
  background: transparent;
  height: 48px;
  background-image: url("../img/ico-search-main.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.header__accesibility {
  border: 1px solid #F2F0ED;
  border-radius: 25px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 30px;
  overflow: hidden;
  margin-left: auto;
}
.header__accesibility li {
  height: 48px;
}
.header__accesibility a,
.header__accesibility button {
  display: inline-block;
  height: 100%;
  min-width: 48px;
  height: 48px;
  padding: 0;
  background-color: transparent;
}

.link--add {
  background-image: url("../img/ico-add.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.btn--contrast {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico-contrast.svg");
  border: none;
  vertical-align: middle;
}
.btn--contrast span {
  min-width: 210px;
}

.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: 1.25rem;
  left: -10px;
  line-height: 50px;
}
.nav-toggle strong {
  margin-left: -20px;
}
.nav-toggle span {
  width: 26px;
  height: 2px;
  background: #000;
  display: block;
  position: absolute;
  left: 12px;
  top: 15px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}
.nav-toggle span:nth-child(2) {
  top: 25px;
}
.nav-toggle span:nth-child(3) {
  top: 35px;
}
.nav-toggle.open span:first-child {
  transform: rotate(45deg);
  top: 24px;
}
.nav-toggle.open span:nth-child(2) {
  left: 50px;
  opacity: 0;
}
.nav-toggle.open span:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  padding-top: 15px;
  padding-bottom: 15px;
}
.header__bottom .btn--red {
  margin-left: 20px;
}

.nav > ul {
  display: flex;
}
.nav > ul > li.active > a {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 4px;
  -webkit-text-decoration-color: #C61523;
          text-decoration-color: #C61523;
}
.nav > ul > li > a {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  display: inline-block;
  padding: 10px 15px 10px 15px;
}
@media (hover: hover) {
  .nav > ul > li > a:hover {
    color: #C61523 !important;
  }
}
@media (min-width: 1200px) {
  .nav > ul > li:first-child > a {
    padding-left: 0;
  }
}
.nav > ul a {
  transition-property: color;
}

.header__social {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.header__social a {
  display: inline-flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}
.header__social a img {
  max-width: 100%;
}

.page__header {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  overflow: hidden;
  background: #B19153;
}
@media (max-width: 576px) {
  .page__header {
    min-height: 200px;
  }
}
.page__header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
  opacity: 0.15;
}
.page__header .breadcrumbs {
  border: none;
}
.page__header .container {
  position: relative;
  z-index: 1;
  text-align: center;
}
.page__header h1,
.page__header h2 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
}
@media (max-width: 576px) {
  .page__header h1,
.page__header h2 {
    font-size: 2.125rem;
  }
}
.page__header .breadcrumbs__list {
  justify-content: center;
}
.page__header .breadcrumb__items span {
  color: #fff;
}
.page__header .breadcrumb__items span:before {
  color: #fff;
}
.page__header .breadcrumb__items a {
  color: #fff;
}
.page__header .breadcrumb__items a:before {
  color: #fff;
}
@media (hover: hover) {
  .page__header .breadcrumb__items a:hover {
    color: #FDF353;
  }
}

header > ul > li {
  position: relative;
}

.menu__item--has-children ul {
  display: none;
}

@media (max-width: 1200px) {
  .header {
    padding-top: 0px;
    position: relative;
    z-index: 100;
  }

  .menu-open .header {
    transition: none;
  }

  .header {
    transition: background 0s ease-out 0.3s;
  }

  .header__wrapper {
    flex-wrap: nowrap;
    height: 120px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
  }

  .nav {
    margin-left: 0;
  }

  .header__top {
    height: 35px;
  }

  .header__bottom {
    margin-top: 10px;
    width: 100%;
    height: 70px;
    border-top: 1px solid #F2F0ED;
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav .nav__wrapper {
    position: absolute;
    top: 100%;
    flex-direction: column;
    background: #fff;
    width: calc(100% + 80px);
    left: -40px;
    max-height: 0;
    height: 100vh;
    overflow: hidden;
    margin-top: 0;
    z-index: 100;
    transition: all 0.3s ease-out;
    background: rgba(230, 226, 219, 0.91);
  }
  .nav .nav__wrapper li {
    background: #fff;
    padding-left: 40px;
  }
  .nav .nav__wrapper a {
    font-size: 26px;
    border-left: 4px solid rgba(128, 112, 77, 0.1);
    width: 100%;
  }
  .nav .nav__wrapper.open {
    max-height: calc(100vh - 120px);
    overflow: auto;
  }
  .nav .nav__wrapper > li:first-child {
    padding-top: 15px;
  }
  .nav .nav__wrapper > li:last-child {
    padding-bottom: 25px;
  }

  .nav-toggle {
    display: block;
  }

  .header.fixed-header-scroll {
    padding-top: 0;
  }
  .header.fixed-header-scroll .header__top {
    height: 25px;
  }
  .header.fixed-header-scroll .header__wrapper {
    height: 75px;
  }
  .header.fixed-header-scroll .header__bottom {
    margin-top: 5px;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .header.fixed-header-scroll .header__bottom .btn--red {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 576px) {
  .header__top {
    height: 25px;
  }

  .nav > ul > li > a {
    text-align: left;
  }

  .header__wrapper {
    height: 100px;
  }

  .header__bottom {
    height: 60px;
  }
  .header__bottom .btn--red {
    margin-left: 0;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .header__bottom .btn--red span {
    display: none;
    display: none;
  }

  .lang__list {
    padding-left: 5px;
    margin-left: 0px;
  }
}
@media (max-width: 440px) {
  .nav .nav__wrapper {
    width: calc(100% + 40px);
    left: -20px;
  }
}
@media (max-width: 400px) {
  .header__top {
    height: 25px;
  }
}
.accordion__btn {
  padding: 20px 40px 20px 0;
  font-weight: 700;
  border: none;
  background: transparent;
  font-size: 18px;
  width: 100%;
  text-align: left;
  color: #000;
  position: relative;
}
.accordion__btn:after {
  content: "";
  position: absolute;
  top: 22px;
  right: 10px;
  display: block;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  transition: all 0.3s ease-out;
  transform-origin: center;
}
.accordion__btn.active:after {
  top: 26px;
  transform: rotate(135deg);
}

.accordion__content {
  padding: 0;
  background: transparent;
  font-size: 18px;
  width: 100%;
  display: none;
}
.accordion__content li:before {
  border-left-color: #000;
}
.accordion__content p,
.accordion__content ol,
.accordion__content ul {
  margin-bottom: 15px;
}

.accordion__body {
  padding: 20px 0;
}

.accordion {
  border-bottom: 1px solid #dedede;
}

.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;
}
.history--back:before {
  content: "" !important;
  width: 50px;
  height: 50px;
  background: url("../img/swiper-arrow.svg") center no-repeat;
  background-size: 16px;
  display: block;
}

.link--underline {
  text-decoration: underline;
  color: #5D5D5D;
}
@media (hover: hover) {
  .link--underline:hover {
    color: #C61523;
  }
}

.link--underline-main {
  text-decoration: underline;
  color: #B19153;
}
@media (hover: hover) {
  .link--underline-main:hover {
    color: #C61523;
  }
}

.btn {
  border: none;
  background-color: #80704D;
  color: #fff !important;
  font-size: 1.125rem;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  transition: background 0.3s ease-out;
  line-height: 1;
  padding: 14px 20px;
  vertical-align: middle;
  text-align: center;
  border-radius: 0;
  border: 3px solid #80704D;
}
@media (hover: hover) {
  .btn:hover {
    border-color: #C7AF87;
    color: #fff !important;
  }
}

.btn--arrow {
  padding-right: 70px;
  text-align: left;
}
.btn--arrow:after {
  content: url("../img/arrow-right.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  width: 18px;
  display: inline-block;
}
@media (max-width: 768px) {
  .btn--arrow {
    padding-right: 60px;
  }
  .btn--arrow:after {
    right: 25px;
  }
}

.btn--red {
  background: #C61523;
  color: #fff !important;
  border-color: #C61523;
}
@media (hover: hover) {
  .btn--red:hover {
    border-color: #940e18;
    color: #fff !important;
  }
}

.hero__outer {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 21;
}
@media (min-width: 1520px) {
  .hero__outer {
    max-width: calc(100% - 80px);
  }
  .hero__outer .hero__wrapper {
    max-width: calc(100% - 120px);
  }
}
.hero__outer .container {
  position: relative;
  z-index: 4;
}

.hero__outer--2 .hero__wrapper {
  min-height: 400px;
  display: flex;
  align-items: center;
}

.hero {
  position: relative;
  padding-top: 90px;
  padding-bottom: 80px;
  overflow: hidden;
}
.hero:before {
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  content: "";
  display: block;
  border: 5px solid #B19153;
  top: 40px;
  left: 40px;
  z-index: 2;
  position: absolute;
}
.hero .back {
  position: absolute;
  right: 40px;
  top: 0px;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  position: absolute;
}
.hero .back:before {
  content: url("../img/back.svg");
  margin-right: 10px;
}

@-webkit-keyframes animateMainImg {
  0% {
    width: 105%;
  }
  100% {
    width: 115%;
  }
}

@keyframes animateMainImg {
  0% {
    width: 105%;
  }
  100% {
    width: 115%;
  }
}
@-webkit-keyframes animateMainImgMobile {
  0% {
    height: 105%;
  }
  100% {
    height: 115%;
  }
}
@keyframes animateMainImgMobile {
  0% {
    height: 105%;
  }
  100% {
    height: 115%;
  }
}
.hero__img {
  position: absolute;
  top: -70px;
  left: 0;
  width: 110%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition: all 0.3s ease-out;
  -webkit-animation-name: animateMainImg;
          animation-name: animateMainImg;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
@media (max-width: 1400px) {
  .hero__img {
    top: 0;
    height: 100%;
    width: auto;
    -webkit-animation-name: animateMainImgMobile;
            animation-name: animateMainImgMobile;
  }
}

.hero__outer--2 .hero__img {
  top: -70px;
  -webkit-animation-name: animateMainImg;
          animation-name: animateMainImg;
  width: 110%;
  height: auto;
}
@media (max-width: 1000px) {
  .hero__outer--2 .hero__img {
    top: 0;
    height: 100%;
    width: auto;
    -webkit-animation-name: animateMainImgMobile;
            animation-name: animateMainImgMobile;
  }
}

.hero__txt {
  color: #fff;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .hero__txt {
    min-height: 500px;
  }
}
.hero__txt h1 {
  position: relative;
  margin-top: 80px;
  margin-bottom: 120px;
  font-size: 50px;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.2;
  padding-left: 20px;
}
.hero__txt h1 span {
  color: #C7AF87;
  font-weight: 400;
}
.hero__txt h1:before, .hero__txt h1:after {
  display: block;
  width: 30px;
  height: 30px;
  content: "";
  position: absolute;
}
.hero__txt h1:before {
  border-top: 5px solid #B19153;
  border-left: 5px solid #B19153;
  top: -15px;
  left: 0px;
}
.hero__txt h1:after {
  border-bottom: 5px solid #B19153;
  border-right: 5px solid #B19153;
  bottom: -15px;
  left: 340px;
}

.body-en .hero__txt h1:after {
  left: 380px;
}

.hero__txt--2 {
  min-height: initial;
  justify-content: center;
}
.hero__txt--2 h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.hero__txt1 {
  font-size: 1.6875rem;
  display: block;
}

.hero__data {
  display: block;
  font-size: 40px;
}

.hero__bottom {
  background: #E6E2DB;
}
.hero__bottom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 1520px) {
  .hero__bottom .container {
    max-width: calc(100% - 80px);
    padding-left: 60px;
    padding-right: 60px;
  }
}

.hero__bottom--2 {
  background-image: linear-gradient(to right, #fff, #fff 56%, #F2F0ED 56%, #F2F0ED 100%);
  position: relative;
}
.hero__bottom--2 .hero__bottom--img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 56%;
}
.hero__bottom--2 .container {
  position: static;
  justify-content: flex-end;
}
.hero__bottom--2 #scrollDown {
  right: 44%;
  font-size: 10px;
  border: none;
  bottom: 20px;
}

.hero__bottom--quote {
  font-style: italic;
  font-size: 1.6875rem;
  text-align: right;
}
.hero__bottom--quote small {
  font-size: 70%;
}

.hero__bottom--img {
  max-width: 30%;
}

#scrollDown {
  position: absolute;
  bottom: 45px;
  right: 630px;
  z-index: 20;
  color: #C7AF87;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-size: 13px;
  font-weight: 500;
  transform: rotate(-90deg);
  background: none;
  border: none;
  transition: none;
  border-bottom: 1px solid rgba(93, 93, 93, 0.5);
  padding-left: 0;
  padding-right: 10px;
}
#scrollDown:before {
  content: url("../img/scroll-down.svg");
  transform: rotate(90deg);
  width: 10px;
}
@media (max-width: 1520px) {
  #scrollDown {
    right: 600px;
  }
}

@media (max-width: 1700px) {
  .hero__bottom--2 {
    background-image: linear-gradient(to right, #fff, #fff 48%, #f2f0ed 48%, #f2f0ed 100%);
  }
  .hero__bottom--2 .hero__bottom--img2 {
    width: 48%;
  }
  .hero__bottom--2 #scrollDown {
    right: 51%;
  }
}
@media (max-width: 1520px) {
  .container.hero__wrapper {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 1200px) {
  .hero__bottom--2 {
    background-image: linear-gradient(to right, #fff, #fff 38%, #f2f0ed 38%, #f2f0ed 100%);
  }
  .hero__bottom--2 .hero__bottom--img2 {
    width: 38%;
  }
  .hero__bottom--2 #scrollDown {
    right: 60%;
  }
}
@media (max-width: 992px) {
  .hero {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .hero__bottom--quote {
    font-size: 23px;
    max-width: 60%;
  }

  #scrollDown {
    right: 57%;
  }
}
@media (max-width: 768px) {
  .hero__bottom .container {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hero__bottom--quote {
    max-width: 100%;
    font-size: 13px;
    padding-left: 30px;
  }

  .hero__bottom--2 {
    background: #F2F0ED;
  }
  .hero__bottom--2 .hero__bottom--img2 {
    width: 100%;
    position: static;
  }
  .hero__bottom--2 #scrollDown {
    right: initial;
    left: -10px;
    bottom: -15px;
  }

  .hero__txt h1 {
    margin-top: 170px;
    margin-bottom: 20px;
  }

  #scrollDown {
    right: initial;
    left: 5px;
    bottom: -20px;
    font-size: 9px;
    border-bottom: none;
  }

  .hero__txt {
    min-height: auto;
  }

  .hero {
    overflow: hidden;
  }
  .hero:after {
    width: 100%;
    height: 50%;
    content: "";
    display: block;
    bottom: 0;
    left: 0px;
    z-index: 1;
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  }

  .hero__img {
    height: 120%;
    width: auto;
    left: initial;
    right: -200px;
    transform: none;
  }

  .hero__txt1 {
    font-size: 14px;
  }

  .hero__txt h1 {
    font-size: 34px;
  }

  .hero__data {
    font-size: 16px;
  }

  .hero__txt h1:after {
    left: 240px;
  }

  .body-en .hero__txt h1:after {
    left: 260px;
  }
}
@media (max-width: 576px) {
  .hero__outer--2 .hero__wrapper {
    min-height: 200px;
  }

  .hero__img {
    height: 100%;
    right: -260px;
  }

  .hero__outer--2 .hero__img {
    right: -100px;
  }
}
@media (max-width: 440px) {
  .hero__txt h1 {
    font-size: 30px;
  }

  .hero .back {
    right: 20px;
  }

  .hero:before {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 20px;
    left: 20px;
  }

  .hero {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container.hero__wrapper {
    max-width: calc(100% - 40px);
  }

  .body-en .hero__txt h1:after {
    left: 230px;
  }
}
.modal-open {
  overflow: hidden;
  height: 100vh;
}
.modal-open .section {
  z-index: 0 !important;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal .link--underline-main {
  display: block;
  margin-bottom: 10px;
}
.modal p {
  margin-bottom: 20px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 120;
  background-color: rgba(230, 226, 219, 0.9);
}
.modal__close {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  outline: none;
  background: none;
  width: 160px;
  height: 60px;
  display: inline-block;
  background: #fff;
  color: #B19153;
  padding: 0 70px 0 0;
  text-align: right;
  text-transform: uppercase;
  color: #80704D;
  font-size: 14px;
  overflow: hidden;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 50px;
  position: absolute;
  top: -2px;
  right: 20px;
  transition: all 0.3s ease-out;
  font-weight: 300;
}
.modal__close:hover {
  background: #F2F0ED;
}
.modal .modal__content {
  width: 100%;
  overflow: auto;
  max-height: 80vh;
}
.modal__container {
  position: relative;
  z-index: 122;
  max-width: 1320px;
  width: 96%;
  top: 5%;
  margin: 0 auto;
  padding: 50px 100px 70px 100px;
  background-color: #fff;
  overflow: auto;
}
@media (max-width: 1200px) {
  .modal__container {
    padding: 50px 65px;
  }
}
@media (max-width: 992px) {
  .modal__container {
    padding: 50px 20px 20px 20px;
    height: auto;
    max-height: 95%;
  }
}
@media (max-width: 576px) {
  .modal__container {
    padding: 40px 20px 20px 20px;
    align-items: flex-start;
  }
}
.modal__title {
  margin-bottom: 15px;
}
.modal .speaker__name {
  font-size: 46px;
}
.modal .speaker__name strong {
  font-size: 60px;
}
.modal .speaker-details__txt {
  padding-top: 30px;
}
.modal .row--60 [class*=col-] {
  margin-bottom: 0px;
}
.modal .speaker-details__img {
  max-width: 100%;
}
.modal .speaker-details__img img {
  width: 100%;
}
.modal h4 {
  font-size: 31px;
  margin-bottom: 10px;
  color: #000;
}
@media (max-width: 992px) {
  .modal__title {
    font-size: 24px;
  }
  .modal h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.modal ul {
  list-style: disc;
  padding-left: 30px;
}
@media (max-width: 576px) {
  .modal__container {
    width: 100%;
    top: 0%;
    padding-top: 60px;
    max-height: 100vh;
  }
  .modal__close {
    position: fixed;
    width: 100%;
  }
}

.file__list {
  padding-bottom: 20px;
  padding-right: 25px;
}

.file__link {
  display: flex;
  align-items: center;
  width: 100%;
  background-image: url("../img/ico-download.svg");
  background-position: left center;
  background-repeat: no-repeat;
  padding: 8px 15px;
  padding-left: 30px;
  color: #B19153;
  font-weight: 700;
  gap: 20px;
  font-size: 1.125rem;
}
@media (hover: hover) {
  .file__link:hover {
    color: #C61523;
    text-decoration: underline;
  }
}

.idea__txt p {
  margin-bottom: 20px;
}

.speaker__list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-height: 520px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-out;
  padding-bottom: 30px;
}
.speaker__list.open {
  max-height: 3000px;
}
.speaker__list:not(.open):after {
  display: block;
  content: "";
  width: 100%;
  height: 160px;
  z-index: 10;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, white 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.speaker__wrapper {
  position: relative;
  padding-bottom: 100px;
}

.speaker__showAll {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 210px;
}
.speaker__showAll :nth-child(2) {
  display: none;
}
.speaker__showAll.active span {
  display: none;
}
.speaker__showAll.active :nth-child(2) {
  display: block;
}

.speaker__btn {
  opacity: 0;
  color: #fff;
  transition: all 0.3s ease-out;
  background: transparent;
  border: none;
  text-transform: uppercase;
  padding: 0;
  padding: 10px 0;
  font-size: 12px;
  display: block;
}
.speaker__btn:after {
  content: url("../img/arrow-more.svg");
  position: relative;
  bottom: 2px;
}

.speaker__item {
  max-width: calc(20% - 12px);
  flex: 0 0 20%;
  background: #E6E2DB;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.speaker__item figure {
  padding-top: 86%;
  height: 0;
  width: 100%;
  position: relative;
}
.speaker__item figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (hover: hover) {
  .speaker__item:hover {
    background: #C61523;
    color: #fff;
  }
  .speaker__item:hover figure img {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
  .speaker__item:hover .speaker__btn {
    opacity: 1;
  }
  .speaker__item:hover .speaker__name {
    color: #fff;
  }
}
@media (hover: none) {
  .speaker__item .speaker__btn {
    opacity: 1;
    color: #000000;
  }
  .speaker__item .speaker__btn:after {
    filter: brightness(0);
  }
}

.speaker__name {
  font-size: 0.875rem;
  color: #000000;
  margin-bottom: 10px;
  font-weight: 500;
}
.speaker__name strong {
  font-size: 1rem;
  display: block;
}

.speaker__txt {
  font-size: 13px;
  margin-borrom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.speaker__txt p:not(:first-child) {
  display: none;
}
.speaker__txt p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.speaker__bottom {
  padding: 10px 20px;
}

.schedule {
  background: #F2F0ED;
  padding-bottom: 60px;
}

.schedule__title {
  display: flex;
  align-items: center;
  gap: 30px;
}

.title__data {
  color: #000000;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 20px;
  border: 1px solid #B19153;
}
.title__data > :first-child {
  font-size: 54px;
}

.schedule__item {
  border-left: 4px solid rgba(128, 112, 77, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  font-size: 0.8125rem;
  padding-bottom: 20px;
  height: auto !important;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.schedule__item p {
  margin-bottom: 7px;
}
.schedule__item h4 {
  color: #000000;
  min-height: 50px;
  margin-bottom: 15px;
  font-size: 1.125rem;
  margin-bottom: 20px;
}
@media (hover: hover) {
  .schedule__item:hover {
    background: #FBFAF9;
    border-color: #FBFAF9;
  }
}

.schedule__time {
  color: #80704D;
  font-size: 1.6875rem;
  margin-bottom: 20px;
  display: block;
}

.swiper__nav {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 48px;
  position: relative;
  display: flex;
  gap: 0px;
  align-items: center;
}
.swiper__nav .swiper-button-prev, .swiper__nav .swiper-button-next {
  min-width: 48px;
  height: 48px;
  margin-top: 0;
  position: static;
}
.swiper__nav .swiper-button-prev {
  justify-content: flex-start;
  left: 0;
}
.swiper__nav .swiper-button-prev:after {
  content: url("../img/swiper-left.svg");
  font-size: initial;
}
.swiper__nav .swiper-button-next {
  justify-content: flex-end;
  right: 0;
}
.swiper__nav .swiper-button-next:after {
  content: url("../img/swiper-right.svg");
  font-size: initial;
}
.swiper__nav .swiper-scrollbar {
  width: 100%;
  background: rgba(128, 112, 77, 0.2);
}
.swiper__nav .swiper-scrollbar-drag {
  height: 8px;
  height: 8px;
  background: #80704D;
  cursor: pointer;
}

.partners {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
  padding-left: 30px;
  border-left: 4px solid rgba(128, 112, 77, 0.1);
}
.partners img {
  max-width: 180px;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}
.partners p {
  font-size: 0.875rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.partners--2 {
  gap: 30px;
  justify-content: space-between;
  max-width: 560px;
}

.partners--3 {
  min-height: 220px;
}

.save {
  background: #F2F0ED;
  overflow: hidden;
  padding-bottom: 60px;
  position: relative;
}

.save__wrapper {
  display: flex;
}

.save__left {
  flex: 0 0 50%;
  max-width: 50%;
}
.save__left .alert {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #80704D;
  margin-top: 20px;
}

.save__img {
  position: absolute;
  bottom: 0px;
  left: 60%;
  height: 100%;
  width: auto;
}

.save__form .form__input {
  max-width: 480px;
}

.contact h3 {
  font-size: 1.125rem;
}
.contact a {
  color: #5D5D5D;
}
@media (hover: hover) {
  .contact a:hover {
    color: #80704D;
  }
}

.map__wrapper iframe {
  max-width: 100%;
}

.file__wrapper {
  position: relative;
  border-right: 4px solid rgba(128, 112, 77, 0.1);
  margin-bottom: 40px;
}

.goToTop {
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  right: -50px;
  bottom: 55px;
  color: #B19153;
  font-weight: 500;
  transform: rotate(-90deg);
  border: none;
  background: transparent;
}
.goToTop:after {
  content: url("../img/scroll-down.svg");
  transform: rotate(-90deg);
  display: inline-block;
  position: relative;
  left: 8px;
}

@media (max-width: 1520px) {
  .modal .speaker__name {
    font-size: 36px;
  }
  .modal .speaker__name strong {
    font-size: 40px;
  }
}
@media (max-width: 1200px) {
  .speaker__list {
    gap: 12px;
  }

  .speaker__item {
    max-width: calc(25% - 9px);
    flex: 0 0 25%;
  }
}
@media (max-width: 992px) {
  .speaker__list {
    gap: 18px;
  }

  .speaker__item {
    max-width: calc(33.33% - 12px);
    flex: 0 0 33.33%;
  }

  .partners--2 {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .modal .speaker__name {
    font-size: 28px;
  }
  .modal .speaker__name strong {
    font-size: 34px;
  }

  .partners {
    gap: 20px;
  }
  .partners img {
    max-height: 40px;
  }

  .partners--3 {
    min-height: auto;
  }
}
@media (max-width: 576px) {
  .save__wrapper {
    display: block;
  }

  .save__left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .save__img {
    position: absolute;
    height: auto;
    width: 580px;
    left: 0;
    bottom: 0;
  }

  .save__figure {
    position: relative;
    height: 250px;
    overflow: hidden;
    width: 100vw;
    left: -40px;
    top: 60px;
  }
}
@media (max-width: 576px) and (max-width: 440px) {
  .save__figure {
    left: -20px;
  }
}
@media (max-width: 576px) {
  .speaker__list {
    gap: 16px;
  }
}
@media (max-width: 576px) {
  .speaker__item {
    max-width: calc(50% - 8px);
    flex: 0 0 50%;
  }
}
@media (max-width: 576px) {
  .speaker__bottom {
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .schedule__title {
    display: block;
  }
}
@media (max-width: 576px) {
  .title__data {
    margin-top: 20px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .title__data > first-child {
    font-size: 40px;
  }
}
.conference__list {
  padding-left: 0;
  list-style: none;
}
.conference__list h3 {
  font-weight: 300;
  font-size: 1.125rem;
}
.conference__list h3 strong {
  display: block;
  margin-top: 15px;
  font-size: 1.375rem;
}
.conference__list > li {
  border-bottom: 3px solid rgba(128, 112, 77, 0.1);
  padding-top: 25px;
  padding-bottom: 25px;
}
.conference__list ul {
  padding-left: 30px;
  list-style: disc;
  color: #B19153;
}